import React, { useState } from "react";
import styled from "styled-components";
import Invoice, { InvoiceProps } from "./Invoice";

const A4Page = styled.div`
  width: 21cm;
  height: 29.7cm;
  padding: 2cm;
  position: relative;
`;

const Fieldset = styled.fieldset`
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  input {
    border-radius: 4px;
    padding: 0.5rem;
    box-shadow: none;
    outline: 0;
    border: 1px solid #AAA;
  }

  label {
    padding-bottom: 0.25rem;
  }

  button {
    text-align: center;
    // padding: 1rem 3rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 1rem;
  }

  label {
    margin-bottom: 0.25rem;
  }
`;

const RegenerateButton = styled.button`
  margin: 2rem 0 0 5rem;

  @media print {
    display: none;
    margin: 0;
  }
`;

const RemoveButton = styled.button`
  width: 2rem;
  height: 2rem;
  border-radius: 99em;
  border: none;
  background: #d63031;
  outline: 0;
  box-shadow: none;
  color: white;

  &:hover {
    background: #ff7675;
  }

  &:active {
    background: #fd79a8;
  }
`;

const SubmitButton = styled.button`
  background: #0984e3;
  outline: 0;
  box-shadow: none;
  border-radius: 6px;
  border: none;
  padding: 1rem 5rem;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;

  &:hover {
    background: #74b9ff
  }

  &:active {
    background: #6c5ce7;
  }
`;

const DUMMY_DATA: InvoiceProps = {
  vendorName: "EDUCARE SYSTEMS LIMITED",
  vendorAddress:
    "64 Hursst Park Road, \n Twyford, Reading, \n Berkshire, RG10 0EY",
  date: new Date(7 / 12 / 2021),
  invoiceNumber: "1",
  vat: "27",
  subtotal: "135",
  total: "162",
  invoiceItems: [
    {
      name: "Monthly Accountancy Fees",
      // quantity: "1",
      // rate: "115",
      amount: "115",
    },
  ],
};

type FieldSetter<T> = (e: React.ChangeEvent<T>) => void;


function CreateInvoice({ invoice, onSubmit }: { invoice: InvoiceProps | null, onSubmit: (i: InvoiceProps) => void }) {
  const [invNo, setInvNo] = useState<string>(invoice?.invoiceNumber || '');
  const [invDate, setInvDate] = useState<string>(invoice?.date.toLocaleDateString('en-GB') || '');
  const [vendorName, setVendorName] = useState<string>(invoice?.vendorName || '');
  const [vendorAddress, setVendorAddress] = useState<string>(invoice?.vendorAddress || '');
  const [invoiceItems, setInvoiceItems] = useState<({ name: string, amount: string })[]>(invoice?.invoiceItems || [{ name: '', amount: '' }]);
  const [subtotal, setSubtotal] = useState<string>(invoice?.subtotal || '');
  const [vat, setVat] = useState<string>(invoice?.vat || '');
  const [total, setTotal] = useState<string>(invoice?.total || '');

  const handleInvNoChange: FieldSetter<HTMLInputElement> = (e) => {
    setInvNo(e.currentTarget.value);
  };
  const handleDateChange: FieldSetter<HTMLInputElement> = (e) => {
    setInvDate(e.currentTarget.value);
  };

  const handleVendorNameChange: FieldSetter<HTMLInputElement> = e => {
    setVendorName(e.currentTarget.value);
  };

  const handleVendorAddressChange: FieldSetter<HTMLTextAreaElement> = e => {
    setVendorAddress(e.currentTarget.value);
  };

  const handleInvItemNameChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const newInvoiceItems = [...invoiceItems.slice(0, i), { ...invoiceItems[i], name: e.currentTarget.value }, ...invoiceItems.slice(i + 1)];
    setInvoiceItems(newInvoiceItems);
  };

  const handleInvItemAmountChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const newInvoiceItems = [...invoiceItems.slice(0, i), { ...invoiceItems[i], amount: e.currentTarget.value }, ...invoiceItems.slice(i + 1)];
    setInvoiceItems(newInvoiceItems);
  };

  const handleSubtotalChange: FieldSetter<HTMLInputElement> = e => {
    setSubtotal(e.currentTarget.value);
  };

  const handleVatChange: FieldSetter<HTMLInputElement> = e => {
    setVat(e.currentTarget.value);
  };

  const handleTotalChange: FieldSetter<HTMLInputElement> = e => {
    setTotal(e.currentTarget.value);
  };

  const handleAddItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    setInvoiceItems([...invoiceItems, { name: '', amount: '' }]);
  }

  const handleRemoveItem = (e: React.MouseEvent<HTMLButtonElement>, i: number) => {
    setInvoiceItems([...invoiceItems.slice(0, i), ...invoiceItems.slice(i + 1)]);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Shitty validation
    // if (!invNo) {
    //   alert('Enter an Invoice Number')
    // }

    // if (!invDate.match(/^\d\d\/\d\d\/\d\d\d\d$/)) {
    //   alert('Enter a date in the format "DD/MM/YYYY"');
    //   return;
    // }

    onSubmit({
      invoiceNumber: invNo,
      date: new Date(invDate),
      vendorName: vendorName,
      vendorAddress: vendorAddress,
      invoiceItems: invoiceItems,
      vat: vat,
      subtotal: subtotal,
      total: total,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset>
        <label htmlFor="invoice_no">Invoice No.</label>
        <input type="text" name="invoice_no" value={invNo} onChange={handleInvNoChange} placeholder="Invoice Number..." />
        <br />
        <label htmlFor="invoice_date">Invoice No.</label>
        <input type="text" name="invoice_date" value={invDate} onChange={handleDateChange} placeholder="DD/MM/YYYY..." />
      </Fieldset>
      <Fieldset>
        <label htmlFor="vendor_name">Vendor Name</label>
        <input type="text" name="vendor_name" value={vendorName} onChange={handleVendorNameChange} placeholder="Vendor Name..." />
        <br />
        <label htmlFor="vendor_address">Vendor Address</label>
        <textarea name="vendor_address" value={vendorAddress} onChange={handleVendorAddressChange} placeholder="Vendor's address here - format correctly" rows={5} cols={50} />
      </Fieldset>

      <Fieldset>
        <h3>Invoice Items</h3>
        {invoiceItems.map((item, i) => (
          <Row key={i}>
            <div>
              <label htmlFor="invoice_items[].name">Description</label>
              <input type="text" name="invoice_items[].name" value={invoiceItems[i].name} onChange={e => { handleInvItemNameChange(e, i) }} placeholder="Accountancy Fees..." />
            </div>
            <div>
              <label htmlFor="invoice_items[].amount">Net Amount</label>
              <input type="text" name="invoice_items[].amount" value={invoiceItems[i].amount} onChange={e => { handleInvItemAmountChange(e, i) }} placeholder="35.99..." />
            </div>
            {i > 0 ? (
              <div style={{ display: 'flex', justifyContent: 'center', width: '5rem', height: '5rem' }}>
                <RemoveButton type="button" onClick={(e) => { handleRemoveItem(e, i) }}>X</RemoveButton>
              </div>
            ) : null}
          </Row>
        ))}
        <button type="button" onClick={handleAddItem}>Add new item</button>
      </Fieldset>

      <Fieldset>
        <label htmlFor="subtotal">Subtotal</label>
        <input type="text" name="subtotal" value={subtotal} onChange={handleSubtotalChange} placeholder="35.99..." />
        <br />
        <label htmlFor="vat">VAT</label>
        <input type="text" name="vat" value={vat} onChange={handleVatChange} placeholder="7.19..." />
        <br />
        <label htmlFor="total">Total</label>
        <input type="text" name="total" value={total} onChange={handleTotalChange} placeholder="43.19..." />
      </Fieldset>
      <SubmitButton type="submit">Generate Invoice</SubmitButton>
    </form>
  )
}

function App(props: { invoice?: InvoiceProps }) {
  const [invoice, setInvoice] = useState<InvoiceProps | null>(null);
  const [createOverride, setCreateOverride] = useState<boolean>(false);
  const inv = props.invoice || invoice;
  return (
    <>
      {!props.invoice && !createOverride && inv ? (
        <RegenerateButton type="button" onClick={() => { setCreateOverride(true) }}>Regenerate Invoice</RegenerateButton>
      ) : null}
      <A4Page>
        {!createOverride && inv ? (
          <Invoice {...inv} />
        ) : (
          <CreateInvoice invoice={invoice || null} onSubmit={(i: InvoiceProps) => { setCreateOverride(false); setInvoice(i); window.scrollTo(0, 0); }} />
        )}
      </A4Page>
    </>
  );
}

export default App;

import React from "react";
import styled from "styled-components";
import moment from "moment";

interface InvoiceItem {
  name: string;
  // quantity: string;
  // rate: string;
  amount: string;
}

export interface InvoiceProps {
  vendorName: string;
  vendorAddress: string;
  date: Date;
  invoiceNumber: string;
  invoiceItems: InvoiceItem[];
  subtotal: string;
  vat: string;
  total: string;
}

const InvoiceHeader = styled.div`
  display: grid;
  grid-template-columns: 6fr 4fr;
  gap: 10px;
  margin-bottom: 4rem;
  padding: 0 1rem;
`;
const InvoiceHeaderLeft = styled.div``;
const InvoiceHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const InvoiceLetterHead = styled.div`
  margin-bottom: 3rem;
`;
const CompanyName = styled.div`
  font-family: 'Algerian', serif;
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
`;
const CompanyAddress = styled.div`
  line-height: 1.25;
`;

const InvoiceText = styled.div`
  font-weight: 500;
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;
const InvoiceHeaderTable = styled.div`
  margin: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;
const InvoiceHeaderRowName = styled.div`
  text-align: end;
  font-weight: 200;
`;
const InvoicesHeaderRowValue = styled.div`
  text-align: end;
`;

const ClientInformationContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 5fr;
  gap: 20px;
`;
const ClientCompany = styled.div``;
const ClientAddressContainer = styled.div``;
const ClientAddress = styled.div`
  font-weight: 500;
  pre {
    font-weight: 500;
    font-family: inherit;
    margin: 0;
    line-height: 1.25;
  }
`;

const InvoiceContainer = styled.div``;
const InvoiceTable = styled.div`
  margin-bottom: 3rem;
`;
const InvoiceRowHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  gap: 10px;
  background: #383232;
  color: white;
  padding: 0.25rem 1rem;
  border-radius: 6px;
`;
const InvoiceRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  padding: 0.25rem 1rem;
  gap: 10px;
  margin: 0.1rem 0;
`;
const InvoiceRowItem = styled.div<{ leftAlign?: boolean }>`
  padding: 0.25rem 0.5rem;
  ${(props) =>
    props && props.leftAlign ? `text-align: left` : `text-align: right`};
`;

const InvoiceFooter = styled.div`
  bottom: 3rem;
  position: absolute;
  div {
    margin-bottom: 0.25rem;
  }
`;

function Invoice({
  vendorName,
  vendorAddress,
  date,
  invoiceNumber,
  invoiceItems,
  vat,
  subtotal,
  total,
}: InvoiceProps) {
  return (
    <div>
      <InvoiceHeader>
        <InvoiceHeaderLeft>
          <InvoiceLetterHead>
            <CompanyName>AFFIRM ACCOUNTING LIMITED</CompanyName>
            <CompanyAddress>39 Westfield Road</CompanyAddress>
            <CompanyAddress>Bexleyheath</CompanyAddress>
            <CompanyAddress>Kent</CompanyAddress>
            <CompanyAddress>DA7 6LS</CompanyAddress>
          </InvoiceLetterHead>
          <ClientInformationContainer>
            <ClientCompany>{vendorName.toLocaleUpperCase()}</ClientCompany>
            <ClientAddressContainer>
              <InvoiceHeaderRowName
                style={{ textAlign: "left", marginBottom: "0.25rem" }}
              >
                Address
              </InvoiceHeaderRowName>
              <ClientAddress>
                <pre>{vendorAddress}</pre>
              </ClientAddress>
            </ClientAddressContainer>
          </ClientInformationContainer>
        </InvoiceHeaderLeft>
        <InvoiceHeaderRight>
          <InvoiceText>INVOICE</InvoiceText>
          <InvoiceHeaderTable>
            <InvoiceHeaderRowName>No.</InvoiceHeaderRowName>
            <InvoicesHeaderRowValue>{invoiceNumber}</InvoicesHeaderRowValue>
            <InvoiceHeaderRowName>Date</InvoiceHeaderRowName>
            <InvoicesHeaderRowValue>
              {moment(date).format("DD/MM/YYYY")}
            </InvoicesHeaderRowValue>

            <InvoiceHeaderRowName>Payment Terms</InvoiceHeaderRowName>
            <InvoicesHeaderRowValue>Immediate</InvoicesHeaderRowValue>
          </InvoiceHeaderTable>
        </InvoiceHeaderRight>
      </InvoiceHeader>
      <InvoiceContainer>
        <InvoiceTable>
          <InvoiceRowHeader>
            <InvoiceRowItem leftAlign>Item</InvoiceRowItem>
            <InvoiceRowItem></InvoiceRowItem>
            <InvoiceRowItem></InvoiceRowItem>
            <InvoiceRowItem>Amount</InvoiceRowItem>
          </InvoiceRowHeader>
          {invoiceItems.map((item) => {
            return (
              <InvoiceRow key={item.name}>
                <InvoiceRowItem leftAlign>{item.name}</InvoiceRowItem>
                <InvoiceRowItem></InvoiceRowItem>
                <InvoiceRowItem></InvoiceRowItem>
                <InvoiceRowItem>£{item.amount}</InvoiceRowItem>
              </InvoiceRow>
            );
          })}
        </InvoiceTable>
        <InvoiceRow>
          <InvoiceRowItem />
          <InvoiceRowItem />
          <InvoiceRowItem>Subtotal:</InvoiceRowItem>
          <InvoiceRowItem>£{subtotal}</InvoiceRowItem>
        </InvoiceRow>
        <InvoiceRow>
          <InvoiceRowItem />
          <InvoiceRowItem />
          <InvoiceRowItem>VAT (20%):</InvoiceRowItem>
          <InvoiceRowItem>£{vat}</InvoiceRowItem>
        </InvoiceRow>
        <InvoiceRow>
          <InvoiceRowItem />
          <InvoiceRowItem />
          <InvoiceRowItem>Total</InvoiceRowItem>
          <InvoiceRowItem>£{total}</InvoiceRowItem>
        </InvoiceRow>
      </InvoiceContainer>
      <InvoiceFooter>
        <div>Company Reg. No. 11360906</div>
        <div>VAT No. 395489922</div>
      </InvoiceFooter>
    </div>
  );
}

export default Invoice;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import db from './data/db.json';
import "./fonts/Algerian Regular.ttf";


const urlParams = new URLSearchParams(window.location.search);
const id = urlParams.get('id');
const database: any = db;
const props = id && database[id] ? { invoice: database[id] } : {};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App {...props} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
